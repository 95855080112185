/**
 * @generated SignedSource<<83ed64a7c9b2d9119f83a35634854f3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type utils_imageProperties$data = {
  readonly height: number;
  readonly url: string;
  readonly width: number;
  readonly " $fragmentType": "utils_imageProperties";
};
export type utils_imageProperties$key = {
  readonly " $data"?: utils_imageProperties$data;
  readonly " $fragmentSpreads": FragmentRefs<"utils_imageProperties">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "utils_imageProperties",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
};

(node as any).hash = "b88deb8b311e936243d449b1898df070";

export default node;
