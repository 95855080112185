import { useFragment, graphql } from 'react-relay/hooks';
import type { FC } from 'react';
import styled from '@emotion/styled';
import { RichTextJsonContent } from '@pafcloud/rich-text';
import { responsiveFontSize } from '../utils';
import type {
  HeroRichText_contentBlock$key,
  HeroRichText_contentBlock$data,
} from './__generated__/HeroRichText_contentBlock.graphql';
import { HeroCodeAsHighlightedText, HeroHeadingAsParagraph } from './hero-rich-text-styles/heroRichTextStyles';

const contentBlockFragment = graphql`
  fragment HeroRichText_contentBlock on HeroRichTextBlock {
    alignment
    jsonContent {
      json
    }
  }
`;

const HeroText = styled.div({
  marginBottom: 16,
  textAlign: 'inherit',
  fontSize: responsiveFontSize,

  '*': {
    textAlign: 'inherit',
  },

  '&&&': {
    figure: {
      maxWidth: '40vh',
      margin: 'auto',
    },
  },
});

type HeroRichTextComponentProps = {
  jsonContent: HeroRichText_contentBlock$data['jsonContent'];
};

export const HeroRichTextComponent: FC<HeroRichTextComponentProps> = ({ jsonContent }) => (
  <HeroText>
    <RichTextJsonContent
      content={jsonContent}
      overrides={{
        block: HeroHeadingAsParagraph,
        marks: {
          code: HeroCodeAsHighlightedText,
        },
      }}
    />
  </HeroText>
);

type HeroRichTextProps = {
  contentBlock: HeroRichText_contentBlock$key;
};

const HeroRichText: FC<HeroRichTextProps> = ({ contentBlock }) => {
  const { jsonContent } = useFragment(contentBlockFragment, contentBlock);

  return <HeroRichTextComponent jsonContent={jsonContent} />;
};

export default HeroRichText;
