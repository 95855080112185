import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';
import { Font } from '@pafcloud/style';
import { HeroColors } from '../../hero-colors';
import { heroHeadingStyle } from './heroHeadingStyle';

const GOLDEN_RATIO = 1.618;

const HeroHeading = styled.p<{ textSizeScaling: number; bold?: boolean }>(({ textSizeScaling, bold }) => ({
  margin: 0,
  marginBottom: 'min(1rem, 0.25em)',

  fontFamily: Font.Heading,
  fontSize: `calc(1em * (${(GOLDEN_RATIO * textSizeScaling).toFixed(2)}))`,
  fontWeight: bold ? 700 : 'normal',
  lineHeight: 0.9,
  textWrap: 'balance',
  textShadow: HeroColors.TextShadow,
  color: bold ? HeroColors.Heading : HeroColors.Text,
  ...heroHeadingStyle,
}));

const DefaultText = styled.p({
  margin: 0,
  lineHeight: '1.4em',
  fontSize: 'max(0.8em, 1rem)',
  color: HeroColors.Text,
  textWrap: 'balance',
  textShadow: HeroColors.TextShadow,
});

type HeroHeadingProps = {
  level?: string;
  children?: ReactNode;
  className?: string;
};

export const HeroTextStyle: FC<HeroHeadingProps> = ({ level, children, className }) => {
  if (children == null) {
    return null;
  }

  switch (level) {
    case 'h1':
      return (
        <HeroHeading className={className} textSizeScaling={1.66} bold>
          {children}
        </HeroHeading>
      );
    case 'h2':
      return (
        <HeroHeading className={className} textSizeScaling={1.2} bold>
          {children}
        </HeroHeading>
      );
    case 'h3':
      return (
        <HeroHeading className={className} textSizeScaling={0.95} bold>
          {children}
        </HeroHeading>
      );
    case 'h4':
      return (
        <HeroHeading className={className} textSizeScaling={0.75}>
          {children}
        </HeroHeading>
      );
    case 'h5':
    case 'h6':
      return (
        <HeroHeading className={className} textSizeScaling={0.65}>
          {children}
        </HeroHeading>
      );

    // We currently don't have a reason to handle the 'blockquote' or 'normal' cases specially
    default:
      return <DefaultText className={className}>{children}</DefaultText>;
  }
};
