import { graphql } from 'relay-runtime';

export const responsiveFontSize = 'clamp(0.85rem, 0.2857rem + 1.8571vw, 1.5rem)';

graphql`
  fragment utils_imageProperties on Image {
    url
    height
    width
  }
`;
