import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { heroHeadingStyle as heroHeadingStyle_golden } from './heroHeadingStyle.golden';
import { heroHeadingStyle as heroHeadingStyle_goldenV2 } from './heroHeadingStyle.goldenV2';
import { heroHeadingStyle as heroHeadingStyle_quirky } from './heroHeadingStyle.quirky';
import { heroHeadingStyle as heroHeadingStyle_quirky_purple } from './heroHeadingStyle.quirky-purple';
import { heroHeadingStyle as heroHeadingStyle_momentum } from './heroHeadingStyle.momentum';

export const heroHeadingStyle = (() => {
  if ($buildEnv.theme === 'golden') {
    return heroHeadingStyle_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return heroHeadingStyle_goldenV2;
  }
  if ($buildEnv.theme === 'momentum') {
    return heroHeadingStyle_momentum;
  }
  if ($buildEnv.theme === 'quirky') {
    return heroHeadingStyle_quirky;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return heroHeadingStyle_quirky_purple;
  }

  return null;
})();
