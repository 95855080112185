import styled from '@emotion/styled';
import type { RichTextBlockComponent, RichTextMarkComponent } from '@pafcloud/rich-text';
import { HeroColors } from '../../hero-colors';
import { HeroTextStyle } from '../hero-text-styles/heroTextStyle';

const TextHighlight = styled.strong({
  color: HeroColors.TextHighlight,
});

export const HeroCodeAsHighlightedText: RichTextMarkComponent = ({ children }) => {
  return <TextHighlight>{children}</TextHighlight>;
};

export const HeroHeadingAsParagraph: RichTextBlockComponent = ({ value, children }) => {
  return <HeroTextStyle level={value.style}>{children}</HeroTextStyle>;
};
